import { useMemo } from 'react';
import { usePartCustomFieldsQuery } from '../graphql/partCustomFields.generated';
import { IPartCustomField } from '../types';

export const usePartCustomFields = () => {
  const { data } = usePartCustomFieldsQuery();

  const partCustomFields = useMemo((): IPartCustomField[] => {
    if (!data) {
      return [];
    }

    return data.partCustomFields.map((field) => ({
      ...field,
      customFieldIntConfig: field.customFieldIntConfig
        ? {
            min: field.customFieldIntConfig.min ?? undefined,
            max: field.customFieldIntConfig.max ?? undefined,
            interval: field.customFieldIntConfig.interval ?? undefined,
          }
        : undefined,
      customFieldFloatConfig: field.customFieldFloatConfig
        ? {
            min: field.customFieldFloatConfig.min ?? undefined,
            max: field.customFieldFloatConfig.max ?? undefined,
            interval: field.customFieldFloatConfig.interval ?? undefined,
          }
        : undefined,
      customFieldSelectConfig: field.customFieldSelectConfig
        ? {
            multiple: field.customFieldSelectConfig.multiple,
          }
        : undefined,
    }));
  }, [data]);

  return { partCustomFields };
};
